.Contentbuilder {
    background-color: black;
    
    &-block {        
        &.titleTextWithParameters {
            .content {
                .text {
                    margin-bottom: 80px;

                    @include mq($until: $viewport--md) {                        
                        margin-bottom: 20px;
                    }
                }

                .parameters {
                    display: inline-grid;
                    grid-template-columns: repeat(2, auto);
                    grid-gap: 30px 50px;                    
    
                    .parameter {
                        display: flex;
                        flex-direction: column;
    
                        p {
                            margin-bottom: 0;
    
                            strong {
                                color: $cc-blue;
                                text-transform: uppercase;
                            }
                        }
                    }
                    
                    @include mq($until: $viewport--md) {
                        grid-template-columns: auto;
                    }
                    
                    @include mq($until: $viewport--sm) {
                        grid-gap: 20px 0;
                    }
                }
            }
        }

        &.cases {
            background-color: $cc-white;
            position: relative;

            > h1 {
                position: absolute;
                top: 180px;
                left: 8vw;
                z-index: 1;
            }

            .hidden-reference {
                display: flex;
                justify-content: space-between;
                opacity: 0;
                pointer-events: none;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }

            .Cases {
                display: flex;

                .Case {
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    padding-top: 430px;
                    padding-bottom: 180px;
                    width: 100%;
                    position: relative;
                    cursor: pointer;
                    color: unset;
                    text-decoration: none;

                    > div {
                        padding: 60px 0;
                    }

                    h1 {
                        margin-bottom: 10px;

                        .index {
                            font-size: 45px;
                            line-height: 65px;
                            opacity: .3;

                            em {
                                transition: -webkit-text-stroke .2s ease;
                            }
                        }

                        .title {
                            font-size: 30px;
                            line-height: 35px;
                            transition: color .2s ease;     
                        }
                    }

                    .text {
                        transition: color .2s ease;                        

                        strong {
                            transition: color .2s ease;
                        }
                    }

                    &:before {
                        content: '';
                        background-color: $cc-white;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;    
                        transition: background-color .2s ease;                    
                    }

                    .cta {
                        pointer-events: none;
                        transition: color .2s ease;

                        span {
                            background-color: $cc-black;

                            svg {
                                path {
                                    fill: $cc-white;
                                    transition: fill .2s ease;
                                }
                            }
                        }

                        &:hover {
                            color: $cc-white;
                            transform: translateX(0px) !important;
                        }
                    }

                    &:hover {
                        h1 {
                            .index {
                                em {
                                    -webkit-text-stroke: 2px $cc-white;

                                    @include mq($until: $viewport--md) {
                                        -webkit-text-stroke: 1px $cc-white;                
                                    }
                                }
                            }

                            .title {
                                color: $cc-white !important;
                            }
                        }

                        .text {
                            color: $cc-white;

                            strong {
                                color: $cc-white;
                            }
                        }
                        
                        &:before {
                            background-color: rgba($cc-blue, .8);
                        }

                        .cta {
                            color: $cc-white;

                            span {
                                background-color: $cc-white;

                                svg {
                                    path {
                                        fill: $cc-blue;
                                    }
                                }
                            }

                            * {
                                color: $cc-white;
                            }
                        }
                    }

                    * {
                        z-index: 1;
                        position: relative;
                    }

                    &:nth-of-type(1) {
                        padding-left: 8vw;

                        > div {
                            padding-right: 60px;
                        }
                    }
                    
                    &:nth-of-type(2) {
                        > div {
                            padding-right: 60px;
                            padding-left: 60px;
                            border-left: 1px solid rgba($cc-black, .35);
                            border-right: 1px solid rgba($cc-black, .35);
                        }
                    }
                    
                    &:nth-of-type(3) {
                        padding-right: 8vw;

                        > div {
                            padding-left: 60px;
                        }
                    }

                    @include mq($until: $viewport--sm) {
                        &:nth-of-type(2) {
                            > div {
                                margin-left: 40px;
                                margin-right: 40px;
                                padding-left: 0 !important;
                                padding-right: 0 !important;
                            }
                        }
                    }
                }

            }
            
            @include mq($until: 1100px) {                
                > h1 {
                    top: 100px;
                }
                
                .Cases {
                    flex-direction: column;

                    .Case {
                        &:nth-of-type(1) {
                            padding-top: 300px;
                            padding-bottom: 60px;
                        }
                        
                        &:nth-of-type(2) {
                            padding-top: 60px;
                            padding-bottom: 60px;
                        }
                        
                        &:nth-of-type(3) {
                            padding-top: 60px;
                            padding-bottom: 100px;
                        }
                    }
                }
            }
            
            @include mq($until: $viewport--md) {                
                > h1 {
                    top: 60px;
                }
                
                .Cases {
                    flex-direction: column;

                    .Case {
                        h1 {
                            .index {
                                font-size: 25px;
                                line-height: 45px;
                            }

                            .title {
                                font-size: 20px;
                                line-height: 25px;
                            }
                        }

                        &:nth-of-type(1) {    
                            padding-top: 0;
                            padding-bottom: 0;

                            > div {
                                padding-top: 160px;
                                padding-right: 40px;
                            }
                        }
                        
                        &:nth-of-type(2) {
                            padding-top: 0;
                            padding-bottom: 0;

                            > div {
                                padding-right: 40px;
                                padding-left: 40px;
                                border-left: none;
                                border-right: none;;
                                border-top: 1px solid rgba($cc-black, .35);
                                border-bottom: 1px solid rgba($cc-black, .35);
                            }
                        }
                        
                        &:nth-of-type(3) {
                            padding-top: 0;
                            padding-bottom: 0;

                            > div {
                                padding-left: 40px;
                                padding-bottom: 60px;
                            }
                        }
                    }
                }
            }
        }

        &.textImage {
            .Container {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .image {
                    margin-right: 80px;
                    max-width: 675px;
                    max-height: 820px;
                    width: 100%;
                    height: 100%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }                
            }

            &.Container {
                &--dark {
                    .content {
                        max-width: 560px;

                        h1 {
                            * {
                                color: $cc-white !important;
                            }

                            em {           
                                color: rgba($cc-blue, 0) !important; 
                                -webkit-text-stroke: 2px $cc-blue;

                                @include mq($until: $viewport--md) {
                                    -webkit-text-stroke: 1px $cc-blue;                
                                }
                            }
                        }

                        .text {
                            color: $cc-white;
                            max-width: 480px;

                            ul {
                                padding-left: 0;
    
                                li {
                                    margin-left: 15px;
                                    padding-left: 10px;
                                    font-family: $font-Gotham;
                                }
                            }
                        }

                        .cta {
                            margin-top: 60px;
                        }
                    }
                }
            }

            &.single-cross {
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='960' height='1095' viewBox='0 0 960 1095'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='960' height='1095' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_3' data-name='Mask Group 3' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_2' data-name='Path 2' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821Z' transform='translate(-1325.714 -1090.013)' fill='none' stroke='%23fff' stroke-width='2' opacity='0.5'/%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    pointer-events: none;
                    z-index: 1;
            
                    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                        width: 455px;
                        height: 520px;
                        top: -30px;
                        left: -25px;
                        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='455' height='519' viewBox='0 0 455 519'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='455' height='519' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_3' data-name='Mask Group 3' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_2' data-name='Path 2' d='M12.714,10.013H232.7l294.346,373.36L821.4,10.013h216.885L633.957,521.244l404.332,514.335H821.4L527.049,660.7,232.7,1035.579H12.714L417.046,521.244Z' transform='translate(-634.349 -521.335)' fill='none' stroke='%23fff' stroke-width='2' opacity='0.5'/%3E%3C/g%3E%3C/svg%3E");
                    }
                }

                &:after {
                    content: unset !important;
                }
            }

            @include mq($until: 1100px) {
                .Container {
                    flex-direction: column-reverse;
                    justify-content: flex-start;
                    align-items: flex-start;
    
                    .image {
                        margin-top: 40px;
                        margin-right: 0;
                    }
                }
            }
        }

        &.video {
            .video-container {
                height: 100%;
                max-height: 800px;
                width: 100%;                           

                @include mq($until: 1280px) {                    
                    max-height: 600px;                    
                }
                
                @include mq($until: $viewport--sm) {                                        
                    max-height: 300px;                           
                }
            }
        }

        &.ctaBlock, &.titleTextWithParameters {      
            background-color: $cc-white;      

            .Container {
                display: flex;
                justify-content: space-between;

                .title {
                    max-width: 660px;
                    margin-right: 120px;
                }
    
                .content {
                    max-width: 800px;

                    a {
                        color: $cc-blue;
                        font-weight: bold;
                    }
                }
                
                @include mq($until: 1700px) {                    
                    .content {
                        max-width: 500px;
                    }                    
                }
                
                @include mq($until: 1200px) {                    
                    .title {
                        margin-right: 40px;
                    }                    
                }
                
                @include mq($until: 900px) {                    
                    flex-direction: column;                    
                }
            }
        }

        &.partner {
            .Container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .image {
                    width: 100%;
                    height: 100%;
                    max-width: 450px;
                    margin-right: 80px;
                }

                .content {
                    max-width: 600px;
                    width: 100%;

                    h1 {
                        margin-bottom: 10px;
                    }

                    .cta {
                        margin-top: 40px;
                    }
                }

                @include mq($until: 1100px) {
                    .content {
                        max-width: 500px;
                    }
                }
                
                @include mq($until: 900px) {
                    .image {
                        display: none;
                    }
                }
               
                @include mq($until: $viewport--sm) {
                    .content {
                        .cta {
                            margin-top: 30px;
                        }
                    }
                }
            }
        }

        &.clients {
            .Clients {            
                .Client {
                    width: 100%;
                    display: grid !important;
                    grid-template-columns: 40% 60%;
                    grid-template-areas: 'content image';

                    .content {
                        grid-area: content;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        padding: 80px 0;

                        * {
                            color: $cc-white;
                        }
                        
                        .quote {
                            position: relative;
                            padding-top: 40px;
                            font-size: 25px;
                            line-height: 35px;

                            &:before {
                                content: '';
                                position: absolute;
                                width: 100%;
                                height: 17px;
                                background-repeat: no-repeat;
                                background-size: contain;
                                top: 0;
                                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.048' height='16.355' viewBox='0 0 21.048 16.355'%3E%3Cpath id='Path_130' data-name='Path 130' d='M13.185,24.855H16.65l4.905-14.13V10.5h-7.56Zm-10.62,0H6.03l4.905-14.13V10.5H3.375Z' transform='translate(-1.507 -9.5)' fill='none' stroke='%230093d2' stroke-width='2'/%3E%3C/svg%3E");;
                            }
                        }

                        .details {
                            margin-top: 100px;

                            .company {
                                color: $cc-blue;
                            }
                        }
                    }

                    .image {
                        grid-area: image;
                        padding-left: 100px;
                        max-height: 960px;
                        max-width: 920px;
                        height: 100%;
                        overflow: hidden;
                        position: relative; 
                    
                        &:before {
                            content: '';
                            background-color: rgba($cc-black, .4);
                            transition: background-color .2s ease;
                            width: 100%;
                            height: 100%;
                            position: absolute;
                            z-index: 1;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    @include mq($until: 1100px) {
                        grid-template-columns: 100%;
                        grid-template-areas: 'content'
                        'image';

                        .content {
                            padding-bottom: 40px;

                            .quote {
                                font-size: 18px;
                                line-height: 30px;
                            }

                            .details {
                                margin-top: 0;
                            }
                        }

                        .image {
                            padding-left: 0;
                            max-width: 460px;
                        }                        
                    }

                    @include mq($until: $viewport--sm) {
                        .content {
                            padding-top: 60px;
                        }
                    }
                }

                @include mq($until: 1100px) {
                    .slick {
                        &-next, &-prev {
                            top: 0;
                            bottom: unset;
                        }
                    }
                }
            }

            .Container {
                z-index: 1;
            }
        }

        &.images {
            .images {
                display: flex;
                justify-content: center;
                align-items: flex-start;

                figure {
                    position: relative; 
                    
                    &:before {
                        content: '';
                        background-color: rgba($cc-black, .4);
                        transition: background-color .2s ease;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: 1;
                    }

                    &:first-of-type {
                        margin-bottom: 40px;
                    }
                }
            }

            &.amount-1 {
                .images {
                    figure {
                        &:first-of-type {
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }

            > .Container {
                > .quote {
                    margin-top: -40px;
                    max-width: 480px;
                    margin-left: 110px;
    
                    .content {
                        .quote {                        
                            position: relative;
                            padding-top: 40px;
                            font-size: 25px;
                            line-height: 35px;
                            color: $cc-white;
            
                            &:before {
                                content: '';
                                position: absolute;
                                width: 100%;
                                height: 17px;
                                background-repeat: no-repeat;
                                background-size: contain;
                                top: 0;
                                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='21.048' height='16.355' viewBox='0 0 21.048 16.355'%3E%3Cpath id='Path_130' data-name='Path 130' d='M13.185,24.855H16.65l4.905-14.13V10.5h-7.56Zm-10.62,0H6.03l4.905-14.13V10.5H3.375Z' transform='translate(-1.507 -9.5)' fill='none' stroke='%230093d2' stroke-width='2'/%3E%3C/svg%3E");;
                            }
                        }
            
                        .details {
                            margin-top: 100px;
    
                            .name {
                                color: $cc-white;
                            }
            
                            .company {
                                color: $cc-blue;
                            }
                        }
                    }

                    @include mq($until: 1280px) {
                        margin-top: 40px;
                        margin-left: 20px;
                    }

                    @include mq($until: 1100px) {
                        margin-left: 0px;

                        .content {
                            .quote {
                                font-size: 18px;
                                line-height: 30px;
                            }

                            .details {
                                margin-top: 0;
                            }
                        }                     
                    }
                }
            }

            
            &.fullscreen {        
                .images {
                    figure {
                        height: 100%;
                        max-height: 800px;
                        width: 100%;
        
                        img {
                            width: 100%;
                            height: 100%;
                            max-height: inherit;
                            object-fit: cover;
                        }
                    }
    
                    @include mq($until: 1280px) {
                        flex-direction: column;
    
                        figure {
                            max-height: 600px;
                        }
                    }
                    
                    @include mq($until: $viewport--sm) {                    
                        figure {
                            height: 450px;
                            max-height: 450px;
                        }
                    }
                }    
            }

            &.Container, .Container {
                .images {
                    display: flex;
                    justify-content: space-between;
                }

                &.amount-1 {
                    justify-content: center;
                }

                &--dark {
                    figure {
                        width: 620px;
                        height: 748px;
    
                        img {
                            object-fit: cover;
                            height: 100%;
                            width: 100%;
                        }
                    }
    
                    figure + figure {
                        margin-top: 150px;
                        margin-left: 40px;
                    }
                } 
                
                @include mq($until: 1280px) {
                    &--dark {
                        &::after {
                            top: -110px;
                            width: 700px;
                        }
                    }
                }
                
                @include mq($until: 1280px) {
                    .images {
                        flex-direction: column;
                    }
    
                    &--dark {
                        &::after, &::before {
                            z-index: -1;
                        }
    
                        figure {
                            display: flex;
                            justify-content: center;
    
                            max-width: 620px;
                            max-height: 748px;
                            width: 100%;
                            height: 100%;
                        }
    
                        figure + figure {
                            margin-top: 40px;
                            margin-left: 0;
                        }
                    }
                }

                @include mq($until: $viewport--sm) {
                    &--dark {
                        &::after, &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        &.Container, .Container {              
            padding-top: 200px;
            padding-bottom: 200px;
            
            &--light {
                background-color: $cc-white;
            }

            &--dark {
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 50%;
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='960' height='1080' viewBox='0 0 960 1080'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_26' data-name='Rectangle 26' width='960' height='1080' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_8' data-name='Mask Group 8' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_49' data-name='Path 49' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821Z' transform='translate(-1108.714 -1113.013)' fill='%23fff' opacity='0.09'/%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    pointer-events: none;
            
                    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                        display: none;
                    }
                }
            
                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 858px;
                    height: 100%;
                    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='858' height='1095' viewBox='0 0 858 1095'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='858' height='1095' transform='translate(102)' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_3' data-name='Mask Group 3' transform='translate(-102)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_2' data-name='Path 2' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821Z' transform='translate(276.286 -1414.013)' fill='none' stroke='%23fff' stroke-width='2' opacity='0.5'/%3E%3C/g%3E%3C/svg%3E");
                    background-repeat: no-repeat;
                    pointer-events: none;
            
                    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                        width: 455px;
                        height: 520px;
                        top: -30px;
                        left: -25px;
                        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='455' height='519' viewBox='0 0 455 519'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='455' height='519' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_3' data-name='Mask Group 3' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_2' data-name='Path 2' d='M12.714,10.013H232.7l294.346,373.36L821.4,10.013h216.885L633.957,521.244l404.332,514.335H821.4L527.049,660.7,232.7,1035.579H12.714L417.046,521.244Z' transform='translate(-634.349 -521.335)' fill='none' stroke='%23fff' stroke-width='2' opacity='0.5'/%3E%3C/g%3E%3C/svg%3E");
                    }

                    @include mq($until: $viewport--sm) {
                        display: none;
                    }
                }                
            }

            

            @include mq($until: 1000px) {
                padding-top: 100px;
                padding-bottom: 100px;
            }
            
            @include mq($until: $viewport--sm) {
                padding-top: 50px;
                padding-bottom: 50px;                
            }
        }
    }
}