/* Colors: Custom */
//Example: $cc-[NAME]: #......

// [PROJECTNAME] colors
$cc-white: white;
$cc-lightestGrey: #f0f0f0 !default;
$cc-lightGrey: #F4F4F4 !default;
$cc-grey: #B9B9B9 !default;
$cc-darkGrey: #888888 !default;
$cc-darkerGrey: #8B8B8B !default;
$cc-darkestGrey: #3B3B3B !default;
$cc-black:#293241 !default;
$cc-blackShade: #435572 !default;
$cc-yellow: #E8BB69 !default;
$cc-yellowShade: #c59f59 !default;

// Fraxinus colors
$cc-blue: #0093d2 !default;