.HomePage {
    .Banner {
        @include mq($until: 1100px) {
            &-content {
                margin-left: 100px;
                margin-right: 100px;
            }
        }
        
        @include mq($until: $viewport--md) {
            padding-top: 150px;
            padding-bottom: 60px;

            &-content {
                margin-left: 30px;
                margin-right: 30px;
            }
        }

        position: relative; 
                    
        &:before {
            content: '';
            background-color: rgba($cc-black, .4);
            transition: background-color .2s ease;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
        }
    }

    .MediaText {
        h1 {
            * {
                color: $cc-white !important;
            }

            em {
                color: rgba($cc-white, 0) !important;
            }
        }

        @include mq($until: $viewport--sm) {
            padding-top: 60px;
            padding-bottom: 60px;
            
            &-text {
                margin-bottom: 30px;
            }
        }
    }

    .base-content {
        position: relative;
        padding-bottom: 200px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 1014px;
            height: 100%;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='1014' height='1916' viewBox='0 0 1014 1916'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_24' data-name='Rectangle 24' width='1014' height='1916' transform='translate(-54 -821)' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_7' data-name='Mask Group 7' transform='translate(54 821)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_48' data-name='Path 48' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821Z' transform='translate(-66.714 -1090.013)' fill='none' stroke='%23fff' stroke-width='2' opacity='0.5'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            pointer-events: none;
            z-index: 0;

            @include mq($until: $viewport--sm) {
                width: 1000px;
                height: 900px;
                z-index: 0;
                transform: scale(0.5);
                transform-origin: top right;
            }
        } 
        
        &::after {
            content: '';
            position: absolute;
            bottom: -200px;
            left: -200px;
            width: 960px;
            height: 1080px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='960' height='1080' viewBox='0 0 960 1080'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_26' data-name='Rectangle 26' width='960' height='1080' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_8' data-name='Mask Group 8' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_49' data-name='Path 49' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821Z' transform='translate(-1108.714 -1113.013)' fill='%23fff' opacity='0.09'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            pointer-events: none;
            z-index: -1;
        } 

        .image {
            height: 100%;
            max-height: 820px;
            width: 100%;

            position: relative; 
                    
            &:before {
                content: '';
                background-color: rgba($cc-black, .4);
                transition: background-color .2s ease;
                width: 100%;
                height: 100%;
                position: absolute;
                z-index: 1;
            }

            img {
                width: 100%;
                height: 100%;
                max-height: inherit;
                object-fit: cover;
            }

            @include mq($until: 1280px) {
                max-height: 600px;
            }
            
            @include mq($until: $viewport--sm) {                    
                max-height: 450px;
                height: 450px;
            }
        }

        .Container {
            &:first-of-type {
                margin-top: 200px;
            }

            &.video {
                margin-top: 200px;

                .video-container {
                    max-height: 600px;
                }
            }

            &.textblock {
                display: flex;

                h1 {
                    margin-right: 160px;

                    * {
                        color: $cc-white !important;
    
                        em {
                            color: rgba($cc-white, 0) !important;
                        }
                    }
                }

                > div {
                    > div {
                        max-width: 550px;
                        color: $cc-white;
                    }
                }
            }

            @include mq($until: 1000px) {    
                &.video {
                    margin-top: 100px;    
                    
                    .video-container {
                        max-height: 400px;
                    }
                }

                &.textblock {
                    flex-direction: column;
                }                
            }

            @include mq($until: $viewport--md) {
                &:first-of-type {
                    margin-top: 100px;
                }
            }
            
            @include mq($until: $viewport--sm) {
                &.video {
                    margin-top: 60px;

                    .video-container {
                        max-height: 300px;
                    }
                }

                &:first-of-type {
                    margin-top: 60px;
                }
            }
        }

        @include mq($until: $viewport--md) {
            padding-bottom: 100px;
        }
        
        @include mq($until: $viewport--sm) {
            padding-bottom: 60px;
        }
    }

    .NewsItems {
        background-color: $cc-blue;

        h1 {
            max-width: 400px;
            width: 100%;
            margin-right: 150px;

            * {
                color: $cc-white !important;

                em {
                    color: rgba($cc-white, 0) !important;
                    -webkit-text-stroke: 2px $cc-white;

                    @include mq($until: $viewport--md) {
                        -webkit-text-stroke: 1px $cc-white;                
                    }
                }
            }
        }

        .Container {
            padding-top: 200px;
            padding-bottom: 200px;
            display: flex;

            > div {
                width: 100%;

                a {
                    display: flex;
                    justify-content: space-between;    
                    text-decoration: none;
                    color: $cc-white;   
                    padding: 25px 0;
                    font-size: 25px;
                    
                    &:first-of-type {
                        padding-top: 0;
                    }

                    h3 {
                        margin-right: 40px;
                        font-size: 25px;
                    }
                    
                    .cta {
                        pointer-events: none;
                        transition: transform .2s ease;

                        span {
                            margin-right: 0;
                        }

                    }
                    
                    &:hover {
                        .cta {
                            transform: translateX(5px);
                        }
                    }
                }

                a + a {
                    border-top: 1px solid rgba($cc-white, .3);
                }
            }
        }

        @include mq($until: 1500px) {
            h1 {
                margin-right: 40px;
            }

            > div {
                a {
                    font-size: 24px !important;
                }
            }
        }
        
        @include mq($until: 1000px) {
            h1 {
                margin-bottom: 60px;
            }

            > div {
                a {
                    font-size: 20px !important;
                }
            }

            .Container {
                padding-top: 150px;
                padding-bottom: 150px;
                flex-direction: column;
            }
        }
        
        @include mq($until: $viewport--md) {
            .Container {
                padding-top: 100px;
                padding-bottom: 100px;

                > h1 {
                    margin-bottom: 30px;
                }
            }

            > div {
                a {
                    padding: 10px 0 !important;
                    
                    &:first-of-type {
                        padding-top: 0;
                    }
                }
            }
        }
        
        @include mq($until: $viewport--sm) {
            .Container {
                padding-top: 60px;
                padding-bottom: 60px;
            }
        }
    }
}