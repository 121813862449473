.AboutPage {
    .header {
        > div {
            display: flex;
            align-items: flex-start;

            .page-header {
                max-width: 700px;
                width: 100%;

                @include mq($until: 1600px) {
                    max-width: 550px;
                }
                
                @include mq($until: 1200px) {
                    max-width: 480px;
                }
            }
            
            
            figure {
                margin-left: 100px;
                padding-top: 320px;
                position: relative;
                overflow: hidden; 
                    
                &:before {
                    content: '';
                    background-color: rgba($cc-black, .4);
                    transition: background-color .2s ease;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                }

                img {
                    min-height: 870px;
                    object-fit: cover;
                }
            }
            
            @include mq($until: 1500px) {
                .page-header, figure {
                    padding-top: 250px;
                }
            }
            
            @include mq($until: 1100px) {
                flex-direction: column;

                .page-header {
                    padding-top: 160px;
                    margin-bottom: 60px;
                }

                figure {
                    padding-top: 0;
                    margin-left: auto;
                    max-width: 500px;

                    img {
                        min-height: 600px;
                    }
                }
            }

            @include mq($until: $viewport--sm) {
                figure {
                    img {
                        min-height: 300px;
                    }
                }
            }
        }
    }

    .main {
        margin-top: -140px;

        .text {
            max-width: 720px;
        }

        > .Container {
            @include mq($until: $viewport--sm) {
                padding-bottom: 60px;
            }
        }

        .howWeDoList {
            &-overview {
                display: flex;
                flex-wrap: wrap;
            }

            &-tile {
                max-width: 500px;
                width: 50%;
                margin-top: 100px;
                display: flex;                

                figure {
                    width: 50px;

                    svg {
                        width: 70px;
                    }
                }

                > div {
                    margin-left: 50px;
                    margin-top: 15px;
                }

                h3 {
                    font-size: 16px;
                    margin-bottom: 5px;

                    span {
                        color: $cc-blue;
                    }
                }

                &:nth-of-type(odd) {
                    margin-right: 150px;
                }
                
                &:nth-of-type(even) {
                    margin-left: 150px;
                }
            }

            @include mq($until: 1800px) {
                &-tile {
                    &:nth-of-type(odd) {
                        margin-right: 160px;
                    }
                    
                    &:nth-of-type(even) {
                        margin-left: 0;
                    }
                }
            }
            
            @include mq($until: 1150px) {
                &-tile {
                    width: 100%;

                    &:nth-of-type(odd) {
                        margin-right: 0;
                    }
                }
            }
            
            @include mq($until: $viewport--sm) {
                &-tile {
                    margin-top: 20px;
                    flex-direction: column;

                    > div {
                        margin-left: 0;
                    }
                }
            }
        }

        .parameters {
            &-overview {
                background-color: $cc-blue;       
                                        
                .Container {
                    display: flex;
                    justify-content: center;
                    padding-top: 40px;
                    padding-bottom: 40px;

                    @include mq($until: $viewport--md) {
                        flex-wrap: wrap;
                    }
                }
            }

            &-tile {
                max-width: 300px;
                margin: 50px 70px;
                display: flex;                
                flex-direction: column;

                h1 { 
                    margin-bottom: 0;

                    * {
                        font-size: 30px;
                        line-height: 40px;
                    }

                    em {
                        font-size: 56px;
                        line-height: 60px;
                        padding-bottom: 10px;
                    }
                }

                @include mq($until: 1900px) {
                    margin: 50px 70px;

                    h1 {
                        * {
                            font-size: 20px;
                        }

                        em {
                            font-size: 40px;
                            line-height: 1;
                        }
                    }
                }

                @include mq($until: 1300px) {
                    margin: 50px 30px;
                }
            }

            @include mq($until: 1000px) {                
                &-tile {
                    h1 {
                        text-align: center;
                    }
                }
            }
            
            @include mq($until: $viewport--sm) {                

                &-overview {
                    .Container {
                        flex-direction: column;
                        padding-top: 60px;
                        padding-bottom: 60px;
                    }
                }

                &-tile {
                    margin: 20px 0px;

                    h1 {
                        text-align: center;

                        * {
                            font-size: 24px;
                            line-height: 30px;
                        }

                        em {
                            font-size: 40px;
                            line-height: 44px;
                        }
                    }
                }
            }
        }
    }
}