.WhatWeDoPage {
    .header {      
        .page-header {
            .text {
                max-width: 900px;
            }
        } 
        
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 858px;
            height: 100%;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='1007' height='2219' viewBox='0 0 1007 2219'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='1007' height='2219' transform='translate(-47)' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_2' data-name='Mask Group 2' transform='translate(47)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_1' data-name='Path 1' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821l-187.5-237.078Z' transform='translate(-52.714 -10.013)' fill='%23fff' opacity='0.1'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            pointer-events: none;
            z-index: -1;
        }  
        
        .TextBlocks {
            margin-bottom: 200px;

            &-container {
                @include mq($until: $viewport--sm) {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .TextBlock {
                display: flex;

                &:nth-of-type(even) {
                    flex-direction: row-reverse;

                    .slick {
                        &-prev, &-next {
                            right: unset;
                        }

                        &-prev {
                            left: 15px;                            
                        }
                        
                        &-next {
                            left: 55px;                            
                        }
                    }
                }

                > figure, > .content {
                    width: 50%;
                }

                > figure {
                    grid-area: figure;
                    min-height: 620px;                    
                    position: relative; 
                    
                    &:before {
                        content: '';
                        background-color: rgba($cc-black, .4);
                        transition: background-color .2s ease;
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        z-index: 1;
                    }

                    div {
                        &:not(.slick-prev):not(.slick-next) {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .slick {
                        &-slide {
                            
                        }

                        &-list  {
                            div {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        &-prev, &-next {
                            left: unset;
                        }

                        &-prev {
                            right: 55px;                            
                        }
                        
                        &-next {
                            right: 15px;                            
                        }
                    }
                    
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                
                > .content {
                    grid-area: content;
                    background-color: $cc-white;
                    padding: 60px 80px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;

                    h1 {
                        margin-bottom: 10px;

                        .index {
                            font-size: 45px;
                            line-height: 65px;
                            opacity: .3;

                            em {
                                transition: -webkit-text-stroke .2s ease;
                            }
                        }

                        .title {
                            font-size: 30px;
                            line-height: 35px;
                            transition: color .2s ease;     
                        }
                    }

                    p {
                        a {
                            color: $cc-blue;

                            &:focus, &:active {
                                color: $cc-blue;
                            }
                        }
                    }
                
                    .ctas {
                        margin-top: 30px;
                        display: flex;
                        flex-wrap: wrap;
                        margin-bottom: -20px;

                        a {
                            margin-right: 20px;
                            margin-bottom: 20px;
                        }
                    }
                }
            }

            .TextBlock + .TextBlock {
                margin-top: 80px;
            }
        }
        
        @include mq($until: 1200px) {
            .TextBlocks {
                .TextBlock {
                    flex-direction: column !important;

                    > * {
                        width: 100% !important;
                    }

                    > figure {
                        max-height: 350px;
                        min-height: 350px;

                        img {
                            max-height: 350px;
                            min-height: 350px;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        @include mq($until: $viewport--md) {
            .TextBlocks {
                margin-bottom: 150px;
                
                .TextBlock {
                    .content {
                        padding: 40px;

                        .ctas {
                            margin-top: 10px;
                        }

                        h1 {
                            .index {
                                font-size: 35px;
                                line-height: 55px;
                            }

                            .title {
                                word-break: break-word;
                                hyphens: auto;
                                font-size: 24px;
                                line-height: 28px;
                            }
                        }
                    }
                }

                .TextBlock + .TextBlock {
                    margin-top: 40px;
                }
            }
        }
        
        @include mq($until: $viewport--sm) {
            .TextBlocks {
                margin-bottom: 60px;
            }
        }
    }
}