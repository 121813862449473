.FullProductSlider {
    position: relative;

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        padding-bottom: 50px;
    } 
    
    .slick-slide {
        margin: 0 70px !important;
    }

    .slick-arrow-container-global {
        padding-bottom: 0px !important;
    }
}

.Text-Image-slider {
    .slick-list {
        max-width: 1300px !important;
        margin-left: auto;
        margin-right: auto;
        background-color: $cc-lightGrey;
        height: 640px;

        @include mq($from: 961px, $until: 1300px, $media-type: $mq-type, $mq_debug: null) {
            max-width: 960px;
            height: 480px;
        } 

        @include mq($from: 480px, $until: 960px, $media-type: $mq-type, $mq_debug: null) {
            height: unset;
            max-width: 580px;
        } 

        @include mq($until: 580px, $media-type: $mq-type, $mq_debug: null) {
            max-width: 100%;
            height: unset;
        }

        .slick-track {
            height: 100%;

            .slick-slide {
                > div {
                    height: 100%;
                }
            }
        }
    }

    .slider__counter {
        width: 50%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-container {
            max-width: 1300px;
            margin: 30px auto 0;

            @include mq($from: 961px, $until: 1300px, $media-type: $mq-type, $mq_debug: null) {
                max-width: 960px;
            } 

            @include mq($from: 581px, $until: 960px, $media-type: $mq-type, $mq_debug: null) {
                max-width: 580px;
            } 

            @include mq($until: 580px, $media-type: $mq-type, $mq_debug: null) {
                display: none;
            } 
        }

        h3 {
            margin: 0;
            color: $cc-blue;
        }

        .progress {
            background-color: $cc-blue;
            height: 100%;
            width: 0%;
            transition: width .2s ease;

            &-bar {
                background-color: #C7C7C7;
                width: 100%;
                height: 1px;
                margin: 0 30px;
            }
        }
    }
}


.slick-arrow-container-global {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    pointer-events: none;
    
    @include mq($until: 580px, $media-type: $mq-type, $mq_debug: null) {
        justify-content: flex-end;
        padding-bottom: 20px;
    }
    
    &.slick-disabled {
        opacity: .7;

        .slick-prev, .slick-next {
            pointer-events: none;
        }
    }
    
    .slick-arrow-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;   
        padding: 0 50px;
        max-width: 1500px;
    
        @include mq($until: 580px, $media-type: $mq-type, $mq_debug: null) {
            align-items: flex-end;
            width: 150px;
            padding: 0 20px;
        }

        > div {
            &:before, &:after {
                content: unset;
            }
        }

        &.prev {
            justify-content: flex-start;

        }
        
        &.next {
            justify-content: flex-end;
        }

        
    }
}

.slick-prev {
    left: 0;
}

.slick-next {
    right: auto;
    left: 40px;
}

.slick-prev, .slick-next {
    border: 1px solid $cc-white;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: border-color .2s ease;
    pointer-events: all;  
    top: unset;
    bottom: 0;    

    svg {
        path {
            transition: fill .2s ease;
            fill: white;
        }
    }

    &:hover {        
        border-color: $cc-blue;

        svg {
            path {
                fill: $cc-blue;
            }
        }
    }
}

.slick-prev {
    svg {
        transform: rotate(180deg);
    }
}