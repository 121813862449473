.Banner {
    position: relative;
    width: 100vw;
    height: 1080px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    padding-top: 120px;

    @include mq($until: $resp-nav) {
        padding-top: 95px;
    }

    &-video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;

        &:before {
            content: '';
            background-color: rgba($cc-black, .4);
            transition: background-color .2s ease;
            width: 100%;
            height: 100%;
            position: absolute;
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='960' height='1080' viewBox='0 0 960 1080'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='960' height='1080' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_2' data-name='Mask Group 2' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_1' data-name='Path 1' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821Z' transform='translate(-1325.714 -10.013)' fill='%23fff' opacity='0.15'/%3E%3Cpath id='Path_130' data-name='Path 130' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821Z' transform='translate(1889.286 -3823.013)' fill='%23fff' opacity='0.15'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: $black, $alpha: 0.5);
        z-index: 10;
    }
    
    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        height: max-content;
        padding-top: 250px;
        padding-bottom: 130px;
        
        &::before {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='468' height='527' viewBox='0 0 468 527'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='468' height='527' transform='translate(0 -1.131)' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_2' data-name='Mask Group 2' transform='translate(0 1.131)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_1' data-name='Path 1' d='M12.714,10.013H239.167l303,384.33,303-384.33h223.258L652.212,536.266l416.213,529.448H845.167l-303-385.891-303,385.891H12.714L428.927,536.266Z' transform='translate(-652.615 -10.289)' fill='%23fff' opacity='0.15'/%3E%3C/g%3E%3C/svg%3E");
            top: unset;
            bottom: 0;
            height: 525px;
            width: 100%;
        }
    }

    &-content {
        position: relative;
        z-index: 50;
        margin-left: 455px;

        @include mq($from: 769px, $until: 1500px, $media-type: $mq-type, $mq_debug: null) {
           margin-left: 150px;
        } 

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 35px;
            margin-right: 35px;
        }
    }

    &-title {
        margin-bottom: 25px;

        p {
            font-size: 56px;
            font-weight: 700;
            line-height: 65px;
            color: $white;
            margin-bottom: 0;
            text-transform: uppercase;
            
            em {
                font-style: normal;
                -webkit-text-stroke: 2px #fff;
                color: transparent;

                @include mq($until: $viewport--md) {
                    -webkit-text-stroke: 1px #fff;                
                }
            }

            @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 30px;
                line-height: 35px;
            }
        }
    }

    &-text {
        max-width: 675px;
        margin-bottom: 65px;
        
        p {
            margin-bottom: 0;
            color: $white;

            strong {
                color: $white;
            }
        }
    }
}