/* Nav */
.Nav {

    &-list {
        margin-bottom: 0;
        padding-left: 0;
    }

    &-item {
        margin: 0;
        list-style: none;
    }

    &-link {
        position: relative;
        user-select: none;
        display: block;
        font-family: $font-Gotham;
        font-size: 16px;
        font-weight: 700;
        color: $black;
        text-transform: uppercase;
        text-decoration: none;
        margin-right: 40px;
        line-height: 22px;

        &:hover, &:focus, &:active {
            color: $cc-blue;
        }
        
        &.is-active {
            color: $cc-blue;
        }

        @include mq($until: $resp-nav, $media-type: $mq-type, $mq_debug: null) {
            color: $white;
            font-size: 25px;
            margin-right: 0;
            margin-bottom: 20px;

            &:hover, &:active {
                color: $black;
            }

            &.is-active {
                color: $black;
            }
        } 
    }
}