.pagination {
    margin-top: 200px;

    .pages {
        display: flex;
        justify-content: center;
        align-items: center;

        &.reversed {
            flex-direction: row-reverse;
        }

        .page {
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $font-Gotham;
            font-weight: bold;
            font-size: 16px;
            line-height: 25px;
            background-color: $cc-white;
            color: $cc-black;
            transition: color .2s ease, background-color .2s ease;
            text-decoration: none;
            margin: 0 5px;

            &.active {
                background-color: $cc-blue;
                color: $cc-white;
                pointer-events: none;
            }
        }
    }

    @include mq($until: $viewport--md) {
        margin-top: 100px;
    }
    
    @include mq($until: $viewport--sm) {
        margin-top: 60px;
    }
}