.page-header {
    .info {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;        

        .left {
            display: flex;

            * {
                font-family: $font-Gotham;
            }

            p {                
                margin-bottom: 0;
            }

            .address, .contact {
                display: flex;
                flex-direction: column;
                margin-right: 60px;
            }
            
            .tax {
                display: flex;
                flex-direction: column;
                margin-left: 0;
            }

            a {
                &:not(.cta) {
                    text-decoration: none;
                    transition: color .2s ease;

                    &:hover {
                        color: $cc-blue;
                    }
                }
            }
        }

        .right {

        }

        @include mq($until: $viewport--md) {
            flex-direction: column;
            align-items: flex-start;

            .right {
                margin-top: 30px;
            }
        }
        
        @include mq($until: $viewport--sm) {
            .left {
                display: flex;
                flex-direction: column;

                .contact, .tax {
                    margin-top: 10px;
                }
            }
        }
    }
}

.Form {
    .input-fields {
        .Form-controls {
            width: 100%;
            padding: 0 5px;
        }

        &.row {
            &-2 {
                .Form-controls {
                    &:nth-of-type(1){
                        width: 50%;
                    }

                    &:nth-of-type(2), &:nth-of-type(3) {
                        width: 25%;
                    }
                }
            }
        }
    }
}

.ContactPage {
    .Main {
        &::before {
            content: '';
            position: absolute;
            top: -600px;
            right: -170px;
            width: 1014px;
            height: 100%;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='1014' height='1916' viewBox='0 0 1014 1916'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_24' data-name='Rectangle 24' width='1014' height='1916' transform='translate(-54 -821)' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_7' data-name='Mask Group 7' transform='translate(54 821)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_48' data-name='Path 48' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821Z' transform='translate(-66.714 -1090.013)' fill='none' stroke='%23fff' stroke-width='2' opacity='0.5'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            pointer-events: none;
            z-index: -1;
        } 

        @include mq($until: $viewport--sm) {
            .Container--regular {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }

    .Maps-container {
        margin-top: -140px;
        z-index: -1;

        @include mq($until: $viewport--sm) {
            margin-top: -60px;

            .Maps {
                height: 350px;
            }
        }
    }    
}