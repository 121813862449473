/* Heading */
h1 {
    margin-bottom: 30px;

    p {
        font-size: 45px;
        font-weight: 700;
        line-height: 63px;
        color: $white;
        margin-bottom: 0;
        text-transform: uppercase;
        
        em {
            font-style: normal;
            -webkit-text-stroke: 2px $cc-blue;
            color: transparent;
            
            @include mq($until: $viewport--md) {
                -webkit-text-stroke: 1px $cc-blue;                
            }
        }

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 25px;
            line-height: 35px;
        }
    }
    
}