.Filter {    
    &-head {
        position: relative;

        &:not(:last-of-type) {
            margin-right: 10px;
        }

        .ListItemsButtons {
            position: absolute;            
            z-index: 1;
            right: 0;
            pointer-events: none;
            opacity: 0;
            transform: translateY(-10px);
            transition: transform .2s ease, opacity .2s ease;
            background-color: $cc-white;
            border: 2px solid $cc-blue;
            padding: 25px 30px;

            > div {
                display: flex;
                justify-content: flex-end;

                label {
                    cursor: pointer;
                    user-select: none;
                    width: max-content;
                    font-family: $font-Gotham;
                    font-size: 18px;
                    position: relative;  
                    transition: color .2s ease;                  
                }

                input {
                    display: none;

                    &:checked {
                        & ~ label {
                            color: $cc-blue;
                        }
                    }
                }
            }
        }

        h4 {
            position: relative;
            font-weight: bold;
            font-size: 20px;
            line-height: 25px;
            cursor: pointer;
            text-transform: uppercase;
            font-family: $font-Gotham;
            padding: 5px 10px;
            border: 1px solid;
            display: flex;
            align-items: center;
            justify-content: center;

            > span {
                margin-right: 10px;
            }
        }
        
        &:hover {
            .ListItemsButtons {
                pointer-events: all;
                opacity: 1;
                transform: translateY(0px);
            }
        }

        &-container {
            display: flex;
            align-items: center;
            justify-content: center;

            .active-filters {
                margin-right: 40px;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-end;
                margin-left: 80px;
                margin-top: 10px;

                .active-filter {
                    margin-bottom: 10px;
                    padding: 5px 10px;
                    border: 1px solid $cc-blue;

                    span {
                        color: $cc-blue;
                        font-family: $font-Gotham;
                        text-transform: uppercase;
                        font-weight: bold;
                        font-size: 20px;
                        line-height: 25px;
                    }

                    .close {                        
                        margin-left: 15px;
                        cursor: pointer;
                    }
                }

                .active-filter + .active-filter {
                    margin-left: 10px;
                }
            }
        }

        @include mq($until: 1350px) {
            &-container {
                flex-direction: column-reverse;
                align-items: flex-end;

                .active-filters {
                    margin-right: 0;
                    justify-content: end;

                    .active-filter {
                        span {
                            font-size: 16px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }  
        
        @include mq($until: $viewport--sm) {
            transform: scale(.8);
            transform-origin: left top;
            z-index: 1;

            .ListItemsButtons {
                > div {
                    justify-content: flex-start;
                }
            }

            &-container {
                .active-filters {
                    justify-content: flex-start;
    
                    .active-filter {
                        margin-left: 0 !important;
                        
                        span {
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }
    }
    
    @include mq($from: 900px, $until: 1350px) {
        &-head {
            margin: 10px 0 !important;
        }
    }   

    @include mq($until: 900px) {
        right: unset !important;
        left: 0 !important;

        &-container {
            flex-direction: column;
            align-items: flex-start !important; 
            margin-bottom: 40px !important;           
        }

        &-head + &-head {
            h4 {
                margin-top: 30px;
            }
        }
        
        &-head {
            &-container {
                align-items: flex-start !important;                  

                .active-filters {
                    margin-left: 0;
                }
            }
        }
    }    
}