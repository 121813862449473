.Footer {
    padding: 150px 140px 125px;
    background-color: black;

    @include mq($from: 981px, $until: 1330px, $media-type: $mq-type, $mq_debug: null) {
        padding: 75px 50px 62px;
    } 

    @include mq($until: 980px, $media-type: $mq-type, $mq_debug: null) {
        padding: 50px 35px 50px;
    } 

    &-container {
        display: flex;
        justify-content: space-between;
        position: relative;

        @include mq($until: 670px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
            justify-content: flex-start;
        }

        &.bottom {
            margin-top: 40px;
            padding-top: 40px;
            border-top: 1px solid rgba($cc-white, .2);

            a {
                color: $cc-white;
                text-decoration: unset;
                transition: color .2s ease;
            }
            
            .site-by {
                span {
                    transition: color .2s ease;
                }

                &:hover {
                    span {
                        color: $cc-blue;
                    }
                }
            }

            .Footer {
                &-privacy {
                    a {
                        &:first-of-type {
                            padding-right: 10px;
                        }

                        &:hover {
                            color: $cc-blue;
                        }
                    }

                    a + a {
                        position: relative;
                        padding: 0 10px;

                        &:before {
                            content: '';
                            position: absolute;
                            left: -5px;
                            top: 0;
                            width: 1px;
                            height: 100%;
                            background-color: rgba($cc-white, .2);
                        }
                    }
                }
            }

            @include mq($until: 900px) {
                flex-direction: column;
                margin-top: 20px;
                padding-top: 20px;
                
                .site-by {
                    margin-top: 10px;
                }
            }

            @include mq($until: $viewport--sm) {
                .Footer {
                    &-privacy {
                        display: flex;
                        flex-direction: column;
                        
                        a + a {
                            padding: 0;

                            &:before {
                                content: unset;
                            }
                        }
                    }
                }

                .site-by {
                    margin-top: 10px;
                    padding-top: 10px;
                    border-top: 1px solid rgba($cc-white, .2);
                }
            }
        }
    }

    &-wrapper {
        display: flex;
        align-items: flex-end;

        @include mq($from: 890px, $until: 1540px, $media-type: $mq-type, $mq_debug: null) {
            align-items: flex-start;
            display: grid;
            grid-auto-columns: repeat(3, 1fr);
            grid-template-areas: 
            'logo adres data'
            'nav nav nav';
        }

        @include mq($from: 440px, $until: 889px, $media-type: $mq-type, $mq_debug: null) {
            align-items: flex-start;
            display: grid;
            grid-auto-columns: repeat(2, 1fr);
            grid-template-areas: 
            'logo logo'
            'adres data'
            'nav nav';
        }

        @include mq($until: 439px, $media-type: $mq-type, $mq_debug: null) {
            align-items: flex-start;
            display: grid;
            grid-auto-columns: 1fr;
            grid-template-areas: 
            'logo'
            'adres'
            'data'
            'nav';
        }

    }

    &-logo {
        @include mq($from: 1200px, $until: 1900px) {
            svg {
                max-width: 100px;
            }
        }

        @include mq($from: 1541px, $until: 1675px, $media-type: $mq-type, $mq_debug: null) {
            svg {
                width: 160px;
                height: auto;
            }
        }

        @include mq($from: 301px, $until: 1540px, $media-type: $mq-type, $mq_debug: null) {
            grid-area: logo;
        }

        @include mq($until: 300px, $media-type: $mq-type, $mq_debug: null) {
            grid-area: logo;
            
            svg {
                width: 150px;
            }
        }
    }

    &-nav {
        margin-left: 90px;

        @include mq($from: 1541px, $until: 1675px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 50px;
        }

        @include mq($until: 1540px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 0;
            grid-area: nav;
            margin-top: 30px;
        }

        .Nav-list {
            display: flex;

            @include mq($until: 620px, $media-type: $mq-type, $mq_debug: null) {
                flex-direction: column;
            }
        }

        .Nav-item {
            margin-right: 60px;

            @include mq($from: 621px, $until: 1780px, $media-type: $mq-type, $mq_debug: null) {
                margin-right: 30px;
            }

            @include mq($until: 620px, $media-type: $mq-type, $mq_debug: null) {
                margin-right: 0;
                margin-top: 20px;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        .Nav-link {
            color: $white;
            font-size: 16px;
            font-family: $font-Gotham;
            margin-right: 0;
            margin-bottom: 0;

            &:hover {
                color: $cc-blue;
            }
        }

    }

    &-adres {
        margin-left: 100px;

        @include mq($from: 981px, $until: 1540px, $media-type: $mq-type, $mq_debug: null) {
            grid-area: adres;
        }

        @include mq($until: 889px, $media-type: $mq-type, $mq_debug: null) {
            grid-area: adres;
            margin-left: 0;
            margin-top: 30px;
        }

        p {
            color: $white;
            font-size: 16px;
            font-family: $font-Gotham;
            font-weight: 400;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            @include mq($until: $viewport--sm) {
                margin-bottom: 0;
            }
        }
    }

    &-data {
        margin-left: 60px;

        @include mq($from: 981px, $until: 1540px, $media-type: $mq-type, $mq_debug: null) {
            grid-area: data;
        }

        @include mq($from: 440px, $until: 889px, $media-type: $mq-type, $mq_debug: null) {
            grid-area: data;
            margin-left: 40px;
            margin-top: 30px;
        }

        @include mq($until: 439px, $media-type: $mq-type, $mq_debug: null) {
            grid-area: data;
            margin-left: 0px;
            margin-top: 30px;
        }

        p {
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                text-decoration: none;
                color: $white;
                font-size: 16px;
                font-family: $font-Gotham;
                font-weight: 400;

                &:hover, &:active {
                    color: $cc-blue;
                }
            }
        }
    }

    &-socials {
        display: flex;
        align-items: flex-end;        

        @include mq($until: 670px, $media-type: $mq-type, $mq_debug: null) {
            align-items: flex-start;
            margin-top: 30px;
        }        

        a {
            margin-right: 12px;

            &:last-child {
                margin-right: 0;
            }

            svg {
                &:hover {
                    path {
                        stroke: $cc-blue;
                    }
                }
            }
        }

        @include mq($until: $viewport--sm) {
            position: absolute;
            bottom: 0;
            right: 0;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            a {
                margin-right: 0 !important;
            }

            a + a {
                margin-top: 10px;
            }
        }
    }
}