/* Cookie */
.CookieConsent {
    background-color: $cc-blue;
    color: $white;
    padding: u(15) 0;
    transition-property: transform, opacity, visibility;
    transition-duration: .2s;
    transition-delay: 0s, .2s, .2s;

    display: flex;
    align-items: center;
    justify-content: center;
  
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 900;
    opacity: .9;
  
    p {
        margin-bottom: 0;
        font-size: 14px;
    }
  }
  
  .CookieConsent:not(.is-active) {
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
  }
  
  .CookieConsent > .Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .cookie-text {
    font-size: u(15);
  }
  
  .CookieConsent  a {
    font-weight: bold;
    margin-left: u(10);
    color: $cc-lightGrey;
    text-decoration: underline;
  
    &,
    &:active,
    &:focus,
    &:hover {
        color: $white;
    }
  }
  
  .Close {
    cursor: pointer;   
    line-height: 1;
    margin-left: u(10);
    transition: opacity .2s;
    border: 1px solid $cc-white;
    color: $cc-white;
    font-size: 14px;
    padding: 8px 12px;
  
    &:not(:hover) {
        opacity: .75;
    }
  }
  
  