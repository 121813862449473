.Form {
    padding: 140px 155px;
    background-color: $cc-white;

    &-item {
        &--action {
            display: flex;
            justify-content: flex-end;
        }
    }

    .Form-errors {
        padding-left: 0;
        margin-bottom: 0;

        li {
            list-style: none;
            font-family: "Gotham", sans-serif;
            font-size: 14px;
            margin-top: 7px;      
            color: #ea6161;
        }
    }

    .ff-form-success, .ff-form-errors {
        font-family: "Gotham", sans-serif;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }

    .ff-form-success {
        color: #659e65;

        & ~ * {
            display: none;
        }
    }
    
    .ff-form-errors {
        color: #ea6161;
    }

    .ff-errors {
        padding: 0;
        margin-top: 5px;
        margin-bottom: 10px;

        li {
            list-style: none;
            margin: 0;
            padding: 0;
            color: #ea6161;
            font-family: "Gotham", sans-serif;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 12px;
        }
    }

    .hide {
        display: none;
    }

    textarea, input{
        &:focus {
            outline: none;
        }
    }

    .Form-controls {
        &.textarea {
            height: 150px;
            overflow: hidden;
            border-radius: 30px;
        }
    }

    .input-fields {
        margin-top: 10px;   
        display: flex;

        &.row-6 {
            input {
                position: absolute;
            }

            label {
                margin-left: 30px;                
            }
        }
        
        .file {
            input {
                opacity: 0;
                position: absolute;
                pointer-events: none;
                visibility: hidden;
            }
        }

        .file-container {
            display: flex;
            align-items: flex-start;

            .fake-file {
                margin-bottom: 0;
                border-radius: 100px;
            }

            .add-file-btn {
                background-color: $cc-grey;
                color: $cc-black;
                font-weight: 500;
                border-color: $cc-grey;
                transition: opacity .2s ease;
                margin-bottom: 0;
                margin-left: 10px;

                &:hover {
                    opacity: .7;
                }
            }

            @include mq($until: $viewport--sm) {
                flex-direction: column;

                .add-file-btn {
                    margin-left: 0;
                    margin-top: 10px;
                }
            }
        }
        
        label {
            position: relative;
            cursor: pointer;
            padding-left: 20px;
            padding-top: 5px;
            margin-left: 5px;
            margin-top: 5px;

            &:not(.visible) {
                display: none !important;            
            }

            .fakeCheckbox {
                width: 32px;
                height: 32px;
                border: 1px solid $cc-black;
                transition: background-color .2s ease;
                background-color: $cc-white;
                position: absolute;
                top: 0;
                left: -30px;
            }

            span {
                font-family: $font-Gotham;
                user-select: none;

                a {
                    font-weight: bold;
                    color: $cc-black;
                }
            }
        }
    
        textarea {
            height: 200px;

            &::-webkit-scrollbar {
                width: 5px;
            }
            
            &::-webkit-scrollbar-track {
                background-color: $cc-black;
            }
            
            &::-webkit-scrollbar-thumb {
              background-color: $cc-grey;
              outline: 1px solid $cc-black;
              cursor: pointer;
            }
        }
    
        input, textarea, .fake-file  {
            &:not([type=checkbox]) {
                border: 1px solid $cc-black;
                background-color: unset;
                font-size: 16px;
                line-height: 1;
                font-weight: 500;
                font-family: $font-Gotham;
                color: $cc-black !important;
                appearance: none;
                resize: none;
                padding: 15px;
                width: 100%;
                transition: border-color .2s ease;
        
                &::placeholder {
                    color: $cc-black;
                    text-transform: uppercase;
                }               
    
                @include mq($until: $viewport--sm) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }

            &[type=checkbox] {
                &:checked {
                    & ~ label {
                        .fakeCheckbox {
                            background-color: $cc-blue;
                        }
                    }
                }
            }
        }
        
        button {
            font-family: $font-Gotham !important;
            font-size: 16px;
            font-weight: 500;
            margin-top: 0;
            
            @include mq($until: $viewport--sm) {
                font-size: 16px;
                line-height: 20px;
            }
        }
    }

    @include mq($until: 1200px) {
        padding: 70px;
    }
    
    @include mq($until: 1000px) {        
        .input-fields {
            &.row-2 {
                flex-direction: column;

                > .Form-controls {
                    width: 100% !important;

                    &:not(:last-of-type) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    
    @include mq($until: $viewport--md) { 
        padding: 30px;

        .input-fields {
            flex-direction: column;
            align-items: flex-start;   
            
            &.row-1, &.row-3 {
                > .Form-controls {
                    width: 100% !important;

                    &:not(:last-of-type) {
                        margin-bottom: 10px;
                    }
                }
            }

            .Form-item--action {
                justify-content: flex-start;
                margin-top: 20px;
            }
        }
    }

    @include mq($until: $viewport--sm) { 
        .input-fields {
            input, textarea {
                font-size: 14px !important;
            }

            &.row-6 {
                position: relative;
    
                label {
                    .fakeCheckbox {
                        width: 24px;
                        height: 24px;
                    }
                    
                    span {                    
                        display: flex;
                        flex-direction: column;
                        margin-top: -24px;
                        font-size: 14px;
                        margin-left: 40px;
                    }
                }
            }
        }
    }
}