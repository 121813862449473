h1 {
    p {
        line-height: 1 !important;
    }
    
    * {
        color: $cc-black !important;
    }

    em {
        color: rgba($cc-black, 0) !important;
        -webkit-text-stroke: 2px $cc-blue;

        @include mq($until: $viewport--md) {
            -webkit-text-stroke: 1px $cc-blue;                
        }
    }

    &.light {
        * {
            color: $cc-white !important;
        }

        em {            
            color: rgba($cc-white, 0) !important;
            -webkit-text-stroke: 2px $cc-white;

            @include mq($until: $viewport--md) {
                -webkit-text-stroke: 1px $cc-white;                
            }
        }
    }
}

html, body {
    overflow-x: hidden;
}

p, h1, h3 {
    font-family: $font-Gotham;
}

p {
    strong {
        color: $cc-blue;
    }
}

.Container {
    &--regular {
        max-width: 1900px;
        width: 100%;
        margin: 0 auto;
        padding-left: 140px;
        padding-right: 140px;
        
        @include mq($until: 1330px) {
            padding-left: 50px;
            padding-right: 50px;
        }

        @include mq($until: $viewport--sm) {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
    
    &--biggest {
        max-width: unset;
        width: 100%;
        margin: 0 auto;
        padding-left: 40px;
        padding-right: 40px;        
    }
    
    &--small {
        max-width: 1800px;
        width: 100%;
        margin: 0 auto;
        padding-left: 300px;
        padding-right: 300px;

        @include mq($until: 1800px) {
            padding-left: 200px;
            padding-right: 200px;
        }
        
        @include mq($until: 1330px) {
            padding-left: 80px;
            padding-right: 80px;
        }
        
        @include mq($until: $viewport--sm) {
            padding-left: 40px;
            padding-right: 40px;
        }
    }
    
    &--uneven {
        max-width: 1900px;
        width: 100%;
        margin: 0 auto;
        padding-left: 200px;
        padding-right: 50px;

        @include mq($until: 1800px) {
            padding-left: 140px;
        }
        
        @include mq($until: 1330px) {
            padding-left: 50px;
        }
    }
}

.main {
    background-color: $cc-white;

    .Container {
        padding-top: 200px;
        padding-bottom: 200px;
    }
}

.imager-container {
    height: 100%;
}