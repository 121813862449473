.cta {
    color: $cc-blue;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    transition: color .2s ease;
    font-family: $font-Gotham;

    &:focus, &:active {
        color: $cc-blue;
    }

    &:hover {
        color: $white;

        span {
            transform: translateX(5px);
        }
    }     

    span {
        background-color: $white;
        min-width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        transition: transform .3s ease;
    }

    &.dark {
        svg {
            path {
                fill: $cc-white;
            }
        }

        span {
            background-color: $cc-black;
        }

        &:hover {
            color: $cc-black;
        }
    }

    @include mq($until: $viewport--sm) {
        font-size: 14px;

        span {
            min-width: 32px;
        }
    }
}