.NewsPage {
    .header {
        .base-header {            
            > figure {                
                @include mq($from: 1100px) {
                    width: 420px;
                    height: 480px;

                    margin-left: 120px;
                    margin-top: -40px;
                }

                &:before {
                    content: unset !important;
                }
            }
        }
    }
}

.ProjectsPage, .NewsPage, .JobsPage {
    .header {
        z-index: 1;
        padding-bottom: 100px;

        .base-header {
            display: flex;
            padding-top: 320px;

            .page-header {
                padding-top: 0;
                padding-right: 80px;
                padding-bottom: 20px;

                h1 {
                    max-width: 650px;
                }
            }

            > figure {
                width: 650px;
                height: 700px;
                position: relative; 
                    
                &:before {
                    content: '';
                    background-color: rgba($cc-black, .4);
                    transition: background-color .2s ease;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            @include mq($until: 1080px) {
                padding-top: 180px;
                flex-direction: column;

                .page-header {
                    padding-bottom: 50px;
                    margin-bottom: 0;
                    padding-right: 0;
                }

                > figure {
                    margin-bottom: 120px;
                    max-width: 500px;
                    max-height: 600px;
                    width: 100%;
                    height: 100%;
    
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        strong {
            color: $cc-blue !important;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 858px;
            height: 100%;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='1007' height='2219' viewBox='0 0 1007 2219'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_3' data-name='Rectangle 3' width='1007' height='2219' transform='translate(-47)' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_2' data-name='Mask Group 2' transform='translate(47)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_1' data-name='Path 1' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821l-187.5-237.078Z' transform='translate(-52.714 -10.013)' fill='%23fff' opacity='0.1'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            pointer-events: none;
            z-index: -1;
        } 

    }
    
    .sumup {
        background-color: $cc-blue;
        padding: 70px;
        max-width: 600px;
        margin-top: -80px;   
              
        li {
            color: $cc-white;
            list-style: none;
            position: relative;
            font-family: $font-Gotham;
            font-weight: bold;
            
            &:before {
                content: 'x';
                position: absolute;
                left: -20px;
                top: -2px;
                color: $cc-white;
                font-weight: normal;
            }
        }

        &-container {
            z-index: 1;
            position: relative;
            transform: translateY(-30px);
        }

        
        @include mq($until: 1080px) {
            padding: 40px 70px;

            &-container {
                display: flex;
                justify-content: flex-start;
            }
        }

        @include mq($until: $viewport--sm) {
            padding: 30px 40px;
        }
    }
    
    .TextBlocks {
        margin-top: -120px;
        padding: 200px 0;
        background-color: $cc-white;
        z-index: -1;

        .TextBlock {
            .content {
                padding: 0 120px;

                h1 {
                    &.smaller {
                        margin-bottom: 5px;
    
                        * {
                            font-size: 20px;
                            line-height: 24px;
                            color: $cc-black;
                        }
    
                        em {
                            color: $cc-black !important;
                            -webkit-text-stroke: 0 $cc-black;
                        }
                    }
                }

                a {
                    color: $cc-blue;
                    font-weight: bold;
                }

                ol, ul {
                    li {
                        font-family: "Gotham", sans-serif;
                        padding-left: 10px;
                        margin-left: -10px;

                        strong {
                            color: $cc-blue;
                        }
                    }
                }
            }
        }

        .TextBlock + .TextBlock {
            margin-top: 100px;
        }

        @include mq($until: 1080px) {
            padding: 160px 0;

            .TextBlock {
                .content {
                    padding: 0;
                }
            }
        }

        @include mq($until: $viewport--md) {
            padding-bottom: 100px;
        }
        
        @include mq($until: $viewport--sm) {
            padding-bottom: 60px;

            .TextBlock + .TextBlock {
                margin-top: 60px;
            }
        }
    }
}

.TextPagePage {
    .Text {

    }

    .TextBlocks {
        margin-top: 120px;
        padding: 200px 0;
        z-index: -1;

        .TextBlock {
            .content {
                padding: 0 120px;

                h1 {
                    &.smaller {
                        margin-bottom: 5px;
    
                        * {
                            font-size: 20px;
                            line-height: 24px;
                            color: $cc-black;
                        }
    
                        em {
                            color: $cc-white !important;
                            -webkit-text-stroke: 0 $cc-white;
                        }
                    }
                }

                * {
                    color: $cc-white;
                }

                a {
                    text-decoration: none;
                }

                ol, ul {
                    li {
                        font-family: "Gotham", sans-serif;
                        padding-left: 10px;
                        margin-left: -10px;
                    }
                }
            }
        }

        .TextBlock + .TextBlock {
            margin-top: 100px;
        }

        @include mq($until: 1080px) {
            padding: 160px 0;

            .TextBlock {
                .content {
                    padding: 0;
                }
            }
        }

        @include mq($until: $viewport--md) {
            margin-top: 100px;
            padding-bottom: 100px;
            padding-top: 50px;
        }
        
        @include mq($until: $viewport--sm) {
            padding-bottom: 60px;

            .TextBlock + .TextBlock {
                margin-top: 60px;
            }
        }
    }

    .NewsItems {
        background-color: $cc-blue;

        h1 {
            max-width: 400px;
            width: 100%;
            margin-right: 150px;

            * {
                color: $cc-white !important;

                em {
                    color: rgba($cc-white, 0) !important;
                    -webkit-text-stroke: 2px $cc-white;

                    @include mq($until: $viewport--md) {
                        -webkit-text-stroke: 1px $cc-white;                
                    }
                }
            }
        }

        .Container {
            padding-top: 200px;
            padding-bottom: 200px;
            display: flex;

            > div {
                width: 100%;

                a {
                    display: flex;
                    justify-content: space-between;    
                    text-decoration: none;
                    color: $cc-white;   
                    padding: 25px 0;
                    font-size: 25px;
                    
                    &:first-of-type {
                        padding-top: 0;
                    }

                    h3 {
                        margin-right: 40px;
                        font-size: 25px;
                    }
                    
                    .cta {
                        pointer-events: none;
                        transition: transform .2s ease;

                        span {
                            margin-right: 0;
                        }

                    }
                    
                    &:hover {
                        .cta {
                            transform: translateX(5px);
                        }
                    }
                }

                a + a {
                    border-top: 1px solid rgba($cc-white, .3);
                }
            }
        }

        @include mq($until: 1500px) {
            h1 {
                margin-right: 40px;
            }

            > div {
                a {
                    font-size: 24px !important;
                }
            }
        }
        
        @include mq($until: 1000px) {
            h1 {
                margin-bottom: 60px;
            }

            > div {
                a {
                    font-size: 20px !important;
                }
            }

            .Container {
                padding-top: 150px;
                padding-bottom: 150px;
                flex-direction: column;
            }
        }
        
        @include mq($until: $viewport--md) {
            .Container {
                padding-top: 100px;
                padding-bottom: 100px;

                > h1 {
                    margin-bottom: 30px;
                }
            }

            > div {
                a {
                    padding: 10px 0 !important;
                    
                    &:first-of-type {
                        padding-top: 0;
                    }
                }
            }
        }
        
        @include mq($until: $viewport--sm) {
            .Container {
                padding-top: 60px;
                padding-bottom: 60px;
            }
        }
    }
}