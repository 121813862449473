/* Lang */
.Nav--lang {
    @include position(relative, $z: 1);

    &:not(:hover) {
        .Nav-list {
            opacity: 0;
            visibility: hidden;
        }
    }

    // Toggle
    .Nav-toggle {
        cursor: default;
        align-items: center;
        display: flex;
        color: $white;
        font-size: 16px;
        font-family: $font-Gotham;
        font-weight: 700;
        margin-right: 40px;

        @include mq($until: 980px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 20px;
        }

        span {
            svg {
                margin-left: 3px;
                path{
                    fill: $white;
                }
            }
        }
    }

    // List
    .Nav-list {
        @include transition(#{opacity, visibility});
        @include position(absolute, $top: 100%, $left: 0);

        @include mq($from: $resp-nav) {
            right: 0;
            left: unset;
        }
        color: $black;
        background-color: $white;
        flex-direction: column;
    }

    .Nav-item {
        height: auto;
        padding: 10px;
    }

    // Link
    .Nav-link {
        color: $black !important;
        transition: color .2s ease, text-decoration .2s ease;
        margin-left: 0;


        &::after {
            display: none;
        }

        &:hover, &:active, &:focus {
            color: $cc-blue !important;

            &::after {
                display: none;
            }
        }

        &.is-active {
            color: $cc-blue !important;
            
            &::after {
                display: none;
            }
        }
    }
}
