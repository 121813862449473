/* General */

// // LazySizes
// .lazyload,
// .lazyloading {
//     opacity: 0;
// }

// .lazyloaded {
//     @include transition(opacity, 300ms);
//     opacity: 1;
// }
html, body {
    background-color: $black;
}

// Tabbing
body:not(.is-tabbing) {

    span:focus,
    button:focus,
    input:focus,
    select:focus,
    textarea:focus {
        outline: none;
    }
}

.Main {
    position: relative;
    // overflow: hidden;
}