.ProjectsOverviewPage {
    .header {
        display: flex;
        flex-direction: column;

        figure {
            margin-top: -200px;
            z-index: -1;            

            img {
                width: 100%;
            }
        }

        @include mq($until: $viewport--md) {
            figure {
                margin-top: -100px;
            }
        }
    }

    .page-header {
        margin-bottom: 0;
        padding-bottom: 50px;
    }

    @include mq($from: 2100px) {
        .header {
            position: relative;

            figure {
                margin-top: -1000px;
                width: 100%;
            }
        }

        .page-header {
            height: 100vh;
        }
    }

    .Filter-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 80px;

        h1 {
            margin-bottom: 0;
        }
    }

    .main {
        @include mq($until: 1500px) {        
            .Container {
                padding-top: 100px;
                padding-bottom: 100px;
            }
        }

        @include mq($until: $viewport--sm) {
            .Container {
                padding-top: 60px;
                padding-bottom: 60px;
            }
        }

        .load-more {
            margin-top: 150px;

            &:hover {
                span {
                    transform: rotate(90deg);
                }
            }

            &-container {
                display: flex;
                justify-content: center;                
            }

            @include mq($until: 1500px) {
                margin-top: 100px;
            }

            @include mq($until: $viewport--sm) {
                margin-top: 60px;
            }
        }
    }
}

.Projects {
    &Page {
        .header {
            figure {
                position: relative;

                &:before {
                    content: '';
                    background-color: rgba($cc-black, .4);
                    transition: background-color .2s ease;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                }
            }
        }

        .go-back-to-overview {            
            padding-bottom: 40px !important;
            padding-top: 40px !important;

            &-container {
                background-color: $cc-white;
            }

            @include mq($until: $viewport--sm) {
                padding-top: 0px !important;
            }

            a {
                color: $cc-blue;
                text-transform: uppercase;
                font-size: 16px;
                font-weight: 700;
                line-height: 19px;
                display: flex;
                align-items: center;
                text-decoration: none;
                cursor: pointer;
                transition: color .2s ease;
                font-family: $font-Gotham;

                &:focus, &:active {
                    color: $cc-blue;
                }

                &:hover {
                    color: $cc-black;

                    .arrow {
                        transform: translateX(-5px);
                    }
                }     

                .arrow {
                    background-color: $cc-black;
                    min-width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 15px;
                    transition: transform .3s ease;

                    svg {
                        transform: rotate(180deg);

                        path {
                            fill: $cc-white;
                        }
                    }
                }
            }
        }
    }

    &-overview {
        display: flex;
        flex-wrap: wrap;

        &.hide {
            display: none;
        }
    }

    &-tile {
        text-decoration: none;        
        color: $cc-black;
        width: 50%;        
        // display: flex;
        // flex-direction: column;
        // align-items: flex-start;

        &.hide {
            display: none;
        }

        &:nth-of-type(odd) {
            padding-right: 50px;
            padding-top: 150px;
        }
        
        &:nth-of-type(even) {
            padding-left: 50px;
            padding-bottom: 150px;
        }

        figure {
            height: 660px;
            position: relative;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            &:before {
                content: '';
                background-color: rgba($cc-black, .4);
                transition: background-color .2s ease;
                width: 100%;
                height: 100%;
                position: absolute;
            }
        }

        .title {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 30px;

            .text {
                font-size: 25px;
                font-weight: bold;
                text-transform: uppercase;
                line-height: 35px;
                font-family: $font-Gotham;
                color: $cc-black;
                margin-right: 100px;
            }

            .arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 32px;
                height: 32px;
                background-color: $cc-black;
                transition: background-color .2s ease;
                
                svg {
                    path {
                        fill: $cc-white;
                    }
                }
            }
        }

        &:hover {
            figure {
                &:before {                 
                    background-color: rgba($cc-blue, .65);
                }
            }
            .title {
                .arrow {
                    background-color: $cc-blue;
                }
            }
        }

        @include mq($until: 1500px) {                  
            max-width: 500px;
            width: 100%;

            &:nth-of-type(odd) {
                margin-top: 80px;
                padding-top: 0;
                padding-right: 0;
                margin-right: 100px;
            }

            &:nth-of-type(even) {
                padding-top: 0px;      
                margin-bottom: 60px;
                padding-bottom: 0;
                padding-left: 0;
            }

            .title {
                .text {
                    font-size: 20px;
                    line-height: 25px;
                }
            }

            figure {
                height: 500px;
            }
        }    
    }

    @include mq($until: 1400px) {  
        &-overview {
            flex-direction: column;
        }                    

        &-tile {            
            &:first-of-type {
                margin-top: 0 !important;
            }            
        }

        &-tile + &-tile {
            margin-top: 80px !important;
            margin-left: 0 !important;            
            margin-right: 0 !important;            
            margin-bottom: 0 !important;

            &:last-of-type {
                margin-bottom: 0 !important;
            }
        }
    }

    @include mq($until: $viewport--sm) {
        &-tile {
            figure {
                height: 350px;
            }

            .text {
                word-break: break-word;
                hyphens: auto;
                margin-right: 30px !important;
                font-size: 16px !important;
                line-height: 20px !important;
            }

            .arrow {
                min-width: 25px !important;
                height: 25px !important;
                
                svg {
                    transform: scale(0.9);
                }
            }
        }

        &-tile + &-tile {
            margin-top: 40px !important;
        }
    }
}