.MediaText {
    position: relative;
    padding-top: 205px;
    padding-bottom: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        padding-left: 35px;
        padding-right: 35px;
        padding-top: 75px;
        padding-bottom: 125px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 465px;
        height: 85%;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='960' height='1080' viewBox='0 0 960 1080'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_20' data-name='Rectangle 20' width='960' height='1080' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_5' data-name='Mask Group 5' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_47' data-name='Path 47' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821Z' transform='translate(-12.714 -1113.013)' fill='%23fff' opacity='0.09'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        pointer-events: none;

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            display: none;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='960' height='1095' viewBox='0 0 960 1095'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='960' height='1095' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_3' data-name='Mask Group 3' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_2' data-name='Path 2' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821Z' transform='translate(-1325.714 -1090.013)' fill='none' stroke='%23fff' stroke-width='2' opacity='0.5'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        pointer-events: none;

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            width: 455px;
            height: 520px;
            top: -30px;
            left: -25px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='455' height='519' viewBox='0 0 455 519'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='455' height='519' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_3' data-name='Mask Group 3' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_2' data-name='Path 2' d='M12.714,10.013H232.7l294.346,373.36L821.4,10.013h216.885L633.957,521.244l404.332,514.335H821.4L527.049,660.7,232.7,1035.579H12.714L417.046,521.244Z' transform='translate(-634.349 -521.335)' fill='none' stroke='%23fff' stroke-width='2' opacity='0.5'/%3E%3C/g%3E%3C/svg%3E");
        }
    }

    &-image {
        position: relative;
        width: 674px;
        height: 820px;

        @include mq($from: 769px, $until: 1170px, $media-type: $mq-type, $mq_debug: null) {
            width: 510px;
            height: 600px;
        }

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            width: 100%;
            height: 369px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: $black, $alpha: 0.5);
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    
    &-content {
        max-width: 400px;
        margin-left: 150px;

        @include mq($from: 769px, $until: 1330px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 50px;
        }

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 0;
            margin-top: 98px;
        }
    }

    &-text {
        margin-bottom: 65px;
        
        p {
            margin-bottom: 0;
            color: $white;

            strong {
                color: $white;
            }
        }
    }

}