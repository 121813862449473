.FraxinewsPage {
    .header {
        padding-bottom: 200px;

        .page-header {
            z-index: 1;
            position: relative;

            h1 {
                em {
                    color: black !important;
                }
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 862px;
            height: 2047px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='862' height='2047' viewBox='0 0 862 2047'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='862' height='2047' transform='translate(98)' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_3' data-name='Mask Group 3' transform='translate(-98)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_2' data-name='Path 2' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821Z' transform='translate(103.286 -129.013)' fill='%23fff' opacity='0.1'/%3E%3C/g%3E%3C/svg%3E");            
            background-repeat: no-repeat;
            pointer-events: none;
            z-index: -1;
        } 

        .form {
            position: relative;

            > figure {
                position: absolute;
                right: 0;
            }
        }

        @include mq($until: $viewport--sm) {
            .Container--regular {
                padding-left: 20px;
                padding-right: 20px;
            }
        }

        @include mq($until: $viewport--md) {
            padding-bottom: 100px;
        }
        
        @include mq($until: $viewport--sm) {
            padding-bottom: 60px;
        }
    }

    @include mq($until: 1200px) {
        .header {
            .form {
                > figure {
                    transform: translateY(-40px);
                }
            }
        }
    }
    
    @include mq($until: $viewport--md) {
        .header {
            .form {
                > figure {
                    transform: translateY(-30px);
                    top: 0 !important;
                    position: relative;
                    max-width: 270px;
                }
            }
        }
    }
}