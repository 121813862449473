/* Navigation */
.Navigation {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 140px;
    padding-right: 140px;
    height: 100%;

    @include mq($from: 981px, $until: 1330px, $media-type: $mq-type, $mq_debug: null) {
        padding-left: 50px;
        padding-right: 50px;
    } 

    @include mq($until: 980px, $media-type: $mq-type, $mq_debug: null) {
        padding-left: 35px;
        padding-right: 35px;
        flex-direction: row-reverse;
    } 

    &-container {
        width: 100%;
        display: flex;

        @include mq($until: $resp-nav, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
            width: max-content;
            margin: 0 auto;
        } 
    }

    &-button, &-subbutton {
        display: none;
    }

    &-primary {
        .Nav-list {
            display: flex;

            @include mq($until: $resp-nav, $media-type: $mq-type, $mq_debug: null) {
                flex-direction: column;
            } 
        }
    }

    &-sub {
        margin-top: 4px;
        width: 21px;

        @include mq($until: 980px, $media-type: $mq-type, $mq_debug: null) {
            width: auto;
            margin-top: 60px;
        }

        #submenu-button {
            position: relative;
            z-index: 5;
            cursor: pointer;
            user-select: none;
            height: 27px;
            display: flex;
            flex-direction: column;

            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .5s ease-in-out;
            -moz-transition: .5s ease-in-out;
            -o-transition: .5s ease-in-out;
            transition: .5s ease-in-out;

            span {
                display: block;
                position: relative;
                height: 2px;
                width: 21px;
                opacity: 1;
                background-color: $cc-blue;
    
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: .3s ease-in-out;
                -moz-transition: .3s ease-in-out;
                -o-transition: .3s ease-in-out;
                transition: .3s ease-in-out;
    
                &:nth-child(1) {
                    top: 1px;
                }
    
                &:nth-child(2) {
                    top: 7px;
                }
            }

            @include mq($until: 980px, $media-type: $mq-type, $mq_debug: null) {
                display: none !important;
            } 
        }

        .Nav-list {
            display: flex;

            @include mq($until: 980px, $media-type: $mq-type, $mq_debug: null) {
                flex-direction: column;
            }

            .Nav-item {
                .Nav-link {
                    color: $white;

                    @include mq($until: 980px, $media-type: $mq-type, $mq_debug: null) {
                        font-size: 20px;
                        margin-bottom: 17px;
                    }

                    &:hover, &:active {
                        color: $black;
                    }

                    &.is-active {
                        color: $black;
                    }
                }
            }
        }

        .Navigation-sub__content {
            position: absolute;
            top: 0;
            right: -100%;
            opacity: 0;
            height: 100%;
            background-color: $cc-blue;
            display: flex;
            align-items: center;
            width: max-content;
            padding-left: 30px;
            padding-right: 20px;
            transition: all .2s ease-in-out;

            @include mq($until: 980px, $media-type: $mq-type, $mq_debug: null) {
                position: relative;
                right: unset;
                top: unset;
                background-color: unset;
                padding-left: unset;
                padding-right: unset;
                flex-direction: column;
                align-items: flex-start;
                opacity: 1;
            }
        }
    }

    &-subbutton:checked ~ #submenu-button {
        position: absolute;        
        // right: 130px;

        @include mq($from: $resp-nav) {
            right: 20px;
        }

        span {
            background-color: $white;
            
            &:nth-child(1) {
                top: 6px;
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                transform: rotate(135deg);
            }

            &:nth-child(2) {
                top: 4px;
                -webkit-transform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
                transform: rotate(-135deg);
            }
        }
    }

    &-subbutton:checked ~ .Navigation-sub__content {
        transform: translateX(0);
        transition: all .2s ease-in-out;
        opacity: 1 !important;
        pointer-events: all;
        right: 0;
    }





    #menu-button {
        position: relative;
        z-index: 5;
        cursor: pointer;
        user-select: none;
        height: 27px;
        // right: 30px;
        // top: calc(50% - 18px);

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;

        @include mq($from: 981px, $media-type: $mq-type, $mq_debug: null) {
            display: none !important;
        } 

        span {
            display: block;
            position: relative;
            height: 2px;
            width: 21px;
            border-radius: 2px;
            opacity: 1;
            background-color: $cc-blue;
    
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            -o-transition: .3s ease-in-out;
            transition: .3s ease-in-out;

            &:nth-child(1) {
                top: 1px;
            }

            &:nth-child(2) {
                top: 7px;
            }
        }
    }


    &-button:checked ~ #menu-button {
        
        span {
            background-color: $white;
            
            &:nth-child(1) {
                top: 6px;
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                transform: rotate(135deg);
            }

            &:nth-child(2) {
                top: 4px;
                -webkit-transform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
                transform: rotate(-135deg);
            }
        }
    }

    &-button:checked ~ .Navigation-body {
        transform: translateX(0);
        opacity: 1 !important;
        pointer-events: all;
        transition: all .3s ease-in-out;
    }

    &-body {
        z-index: 2;
        display: flex;

        @include mq($until: $resp-nav, $media-type: $mq-type, $mq_debug: null) {
            opacity: 0;
            position: absolute;
            pointer-events: none;
            width: calc(100% - 35px);
            height: 100vh;
            overflow-y: scroll;
            top: 0;
            right: 0;
            background-color: $cc-blue;
            padding-top: 140px;
            transform: translateX(100%);
            transition: all .3s ease-in-out;
        } 
    }
}
