.JobsOverviewPage {
    .header {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 858px;
            height: 100%;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='862' height='2047' viewBox='0 0 862 2047'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='862' height='2047' transform='translate(98)' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_3' data-name='Mask Group 3' transform='translate(-98)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_2' data-name='Path 2' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821Z' transform='translate(103.286 -129.013)' fill='none' stroke='%23fff' stroke-width='2' opacity='0.5'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            pointer-events: none;
            z-index: -1;
        } 

        > div {
            display: flex;
            align-items: flex-start;

            .page-header {
                max-width: 700px;
                width: 100%;

                @include mq($until: 1600px) {
                    max-width: 550px;
                }
                
                @include mq($until: 1200px) {
                    max-width: 480px;
                }
            }
            
            
            figure {
                margin-left: 100px;
                padding-top: 320px;
                position: relative;
                overflow: hidden; 
                margin-bottom: 80px;
                    
                &:before {
                    content: '';
                    background-color: rgba($cc-black, .4);
                    transition: background-color .2s ease;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    z-index: 1;
                }

                img {
                    object-fit: cover;
                }
            }
            
            @include mq($until: 1500px) {
                .page-header, figure {
                    padding-top: 250px;
                }
            }
            
            @include mq($until: 1100px) {
                flex-direction: column;

                .page-header {
                    padding-top: 160px;
                    margin-bottom: 60px;
                }

                figure {
                    padding-top: 0;
                    margin-left: auto;
                    max-width: 500px;
                    margin-bottom: 60px;
                }
            }
        }
    }

    .JobItems {
        .JobItem {
            text-decoration: none;
            color: $cc-black;
            transition: color .2s ease;
            display: flex;
            flex-direction: column;
            padding-bottom: 50px;
            border-bottom: 1px solid rgba($cc-black, .3);
            
            &:not(:nth-of-type(1)) {
                padding-top: 50px;
            }

            @include mq($until: $viewport--sm) {
                padding-bottom: 20px;

                &:not(:nth-of-type(1)) {
                    padding-top: 20px;
                }
            }

            > div {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;

                p {
                    margin-bottom: 0;
                }

                h1 {
                    margin-bottom: 5px;
                    padding-right: 20px;
                }

                .cta {
                    pointer-events: none;
                    transition: transform .2s ease;

                    span {
                        transition: background-color .2s ease;
                    }
                }

                @include mq($until: $viewport--sm) {
                    flex-direction: column;
                    margin-bottom: 15px;
                    
                    h1 {
                        font-size: 18px;
                    }
                }
            }

            &:hover {
                color: $cc-blue;

                .cta {
                    transform: translateX(5px);

                    span {
                        background-color: $cc-blue;
                    }
                }
            }
        }

        @include mq($until: 1000px) {
            padding-top: 100px;
            padding-bottom: 100px;
        }
        
        @include mq($until: $viewport--sm) {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }
}