.page-header {
    padding-top: 320px;
    margin-bottom: 200px;

    .cta {
        margin-top: 40px;
    }

    .light {
        &:not(h1) {
            * {
                &:not(.cta) {
                    color: $cc-white;
                }
            }
        }
    }

    .title {

    }

    .text {
        max-width: 620px;
    }

    @include mq($until: $viewport--md) {
        padding-top: 200px;
        margin-bottom: 120px; 
    }
    
    @include mq($until: $viewport--sm) {
        h1 {
            * {
                font-size: 22px;
            }
        }

        padding-top: 150px;
        margin-bottom: 60px; 
    }
}