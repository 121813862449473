.NewsOverviewPage {
    .header {
        padding-bottom: 200px;

        @include mq($until: $viewport--md) {
            padding-bottom: 100px;
        }
        
        @include mq($until: $viewport--sm) {
            padding-bottom: 60px;
        }
        
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 858px;
            height: 100%;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='862' height='2047' viewBox='0 0 862 2047'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_4' data-name='Rectangle 4' width='862' height='2047' transform='translate(98)' fill='%23fff' stroke='%23707070' stroke-width='1'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Mask_Group_3' data-name='Mask Group 3' transform='translate(-98)' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_2' data-name='Path 2' d='M12.714,10.013H477.367l621.71,788.6,621.729-788.6h458.1L1324.886,1089.821,2178.9,2176.184h-458.1l-621.729-791.8-621.71,791.8H12.714L866.732,1089.821Z' transform='translate(103.286 -129.013)' fill='none' stroke='%23fff' stroke-width='2' opacity='0.5'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            pointer-events: none;
            z-index: -1;
        } 

        .NewsItems {
            display: flex;            
            justify-content: center;  
            flex-wrap: wrap;       

            .NewsItem {            
                display: flex;
                flex-direction: column;    
                text-decoration: none;
                color: $cc-black;
                background-color: $cc-white;
                transition: background-color .2s ease, color .2s ease;
                padding: 110px 90px;
                width: 100%;                

                &-container {
                    max-width: 620px;
                    width: 100%;

                    &.fake {                     
                        pointer-events: none;
                        opacity: 0;                        
                    }

                    &:nth-of-type(odd) {
                        margin-right: 60px;
                    }
                    
                    &:nth-of-type(even) {
                        margin-top: 120px;
                        margin-left: 60px;
                    }

                    @include mq($until: 1800px) {
                        &:nth-of-type(odd) {
                            margin-right: 80px;
                        }
                        
                        &:nth-of-type(even) {
                            margin-left: 0;
                        }

                        a {
                            padding: 60px 50px;
                        }
                    }

                    @include mq($until: 1700px) {
                        max-width: 480px;
                    }
                    
                    @include mq($until: 1300px) {
                        max-width: unset;
                        margin-bottom: 0 !important;

                        &:not(:first-of-type) {
                            margin-top: 40px !important;
                        }
                    }
                    
                    @include mq($until: $viewport--md) {
                        &:not(:first-of-type) {
                            margin-top: 30px !important;
                        }
                    }

                    @include mq($until: $viewport--sm) {
                        a {
                            padding: 40px 30px;
                        }
                    }
                }

                h4 {
                    font-family: $font-Gotham;
                    text-transform: uppercase;
                    font-size: 16px;
                    font-weight: bold;
                    color: $cc-blue;
                    transition: color .2s ease;
                }

                h1 {
                    font-size: 30px;
                    line-height: 35px;
                    margin-bottom: 10px;
                    margin-top: 5px;
                }

                .cta {
                    pointer-events: none;
                    transition: color .2s ease;
                    
                    span {                        
                        transition: background-color .2s ease;
                        
                        svg {
                            path {
                                transition: fill .2s ease;                            
                            }
                        }
                    }
                }

                &:hover {
                    background-color: $cc-blue;
                    color: $cc-white;

                    h4 {
                        color: $cc-white;
                    }

                    .cta {
                        color: $cc-white;

                        span {                        
                            background-color: $cc-white;

                            svg {
                                path {
                                    fill: $cc-blue;
                                }
                            }
                        }
                    }
                }
            }

            @include mq($until: 1300px) {
                flex-direction: column;
            }
        }
    }
}